<template>
  <section class="page">
    <section class="page__section">
      <div class="row">
        <div class="col-58">
          <Breadcrumbs :margin="24"/>
          <h1 class="directions__title">
            Название направления
          </h1>
          <div class="directions__desc">Мы собрали все материалы, которые могут быть полезны, <br />чтобы выбрать профессию и учебное заведение по душе</div>
          <div class="counters" v-if="counters.length !== 0">
            <div class="counters__item" v-for="(counter, key) in counters" :key="key">
              <div class="counters__count">
                {{ counter.value }}
              </div>

              <div class="counters__name">
                {{ counter.text }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-41">
          <div class="main-head-section__image">
            <img :src="require('@/assets/svg/directions-header.svg')" alt="home-header" />
          </div>
        </div>
      </div>
    </section>
    <section class="page__section">
      <h1 class="title">Мероприятия</h1>
      <div class="page__description mb-8">
        Погрузитесь в рабочую атмосферу во время дня без турникетов <br> или подберите ярмарку вакансий подходящей отрасли
      </div>
      <gallery>
        <news-card
          v-for="(a, index) in activity"
          :key="index" />
      </gallery>
    </section>
    <section class="page__section">
      <h1 class="title">Рекомендовано для вас</h1>
      <div class="page__description mb-8">
        Найдите образовательную программу для любого<br> возраста и уровня подготовки
      </div>
      <div class="row">
        <div class="col-50">
          <div class="education-catalog__item">
            <div class="education-catalog__badge"></div>
            <div class="education-catalog__content">
              <div class="education-catalog__item-title">
                123213
              </div>
              <div class="education-catalog__list">
                <div v-for="n in 2" :key="n">
                  <div class="education-catalog__list-item">
                    <div class="education-catalog__list-item-name">
                      123131
                    </div>

                    <div class="education-catalog__list-item-counter">
                      3
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page__section mb-10">
      <h1 class="title">Куда пойти учиться</h1>
      <div class="page__description mb-8">
        Определитесь с выбором и узнайте больше об интересующих вас<br> образовательных программах региона
      </div>
      <div class="row mb-6">
        <div class="col-50">
          <Range :max="1000000"
                 :min="0"
                 :step="1"
                 label="Стоимость до"
          />
        </div>
        <div class="col-50">
          <checkbox
            label="Только бесплатные" />
        </div>
      </div>
      <div class="row mb-8">
        <div class="col-50">
          <Select placeholder="Форма обучения"/>
        </div>
        <div class="col-50">
          <AutocompleteSearch
              :array="[]"
              v-model="filters.search"
              id="institution"
              placeholder="Учебное заведение"
              :isLabel="false"
              :required="true"/>
        </div>
      </div>
      <div class="row">
        <div
            v-for="n in 4"
            :key="n"
            class="col-33 mb-8">
          <CardInstitution />
        </div>
      </div>
    </section>

    <section class="page__section">
      <h1 class="title">Новости</h1>
      <div class="row">
        <div v-for="i in 3" :key="i" class="col-33">
          <news-card />
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'directions',
  async mounted () {
    const { data } = await this.$http.get(`${process.env.VUE_APP_DEFAULT_DEVELOP_HOST}api/counters?code=direction`)
    this.counters = data.data
  },
  data: () => ({
    counters: [],
    activity: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    filters: {
      search: []
    }
  })
}
</script>

<style lang="scss">
.directions{
  &__title{
    font-weight: 800;
    font-size: 56px;
    line-height: 56px;
    color: var(--main-color-dark);
    margin-bottom: 16px;
  }
  &__desc{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-color-dark);
    opacity: 0.72;
    margin-bottom: 24px;
  }
}
</style>
