<template>
  <ProfileEmployerWrapper>
    <router-view></router-view>
  </ProfileEmployerWrapper>
</template>

<script>
export default {
  name: "ProfileEmpJournalView",
};
</script>