<template>
  <div class="inner-page curriculum" v-if="loaded">
    <Breadcrumbs ref="breadcrumbs" :margin="32"/>
    <div class="avatar mb-8" v-if="organizationAvatar">
      <img :src="organizationAvatar">
    </div>
    <div class="row">
      <div class="col-66">
        <h1 class="curriculum__name">{{ curriculum.name }}</h1>
        <div class="curriculum__type">{{ curriculum.type.name }}</div>
        <div class="ProseMirror" v-html="curriculum.description"></div>
        <CurriculumMainInfoCompetitions
            v-if="curriculum.is_admission_exam"
            :competitions="curriculum.competitions"/>
      </div>
      <div class="col-33">
        <CurriculumMainInfo :curriculum="curriculum"/>
      </div>
    </div>
    <div class="row">
      <div class="col-100">
        <CurriculumAdmissions :curriculum="curriculum"/>
        <CurriculumLearningOptions :options="curriculum.learning_options"/>
        <CurriculumSimilar :curriculum="curriculum"/>
      </div>
    </div>
  </div>
</template>

<script>
import CurriculumMainInfo from '../components/Curriculum/CurriculumMainInfo';
import CurriculumAdmissions from '../components/Curriculum/CurriculumAdmissions';
import CurriculumLearningOptions from '../components/Curriculum/CurriculumLearningOptions';
import CurriculumSimilar from '../components/Curriculum/CurriculumSimilar';
import CurriculumMainInfoCompetitions from "../components/Curriculum/CurriculumMainInfoCompetitions";

export default {
  name: 'CurriculumMainView',
  components: {
    CurriculumMainInfoCompetitions,
    CurriculumMainInfo, CurriculumAdmissions, CurriculumLearningOptions, CurriculumSimilar},
  data() {
    return {
      loaded: false,
      curriculum: {},
    };
  },
  computed: {
    organizationAvatar() {
      return this.curriculum.institution.avatar?.url;
    },
  },
  watch: {
    '$route'() {
      this.getCurriculum();
    },
  },
  mounted() {
    this.getCurriculum();
  },
  methods: {
    updateBreadcrumbs() {
      this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.filter(o => o.type !== 'inst');
      this.$route.meta.breadcrumb.push({
        type: 'inst',
        name: this.curriculum.institution.full_name,
        link: 'InstitutionView',
        params: {
          id: this.curriculum.institution.id,
        },
      },
      {
        type: 'inst',
        name: this.curriculum.name,
      });
    },
    getCurriculum() {
      this.loaded = false;
      this.$http.get(`${process.env.VUE_APP_DEFAULT_DEVELOP_HOST}api/curricula/${this.$route.params.id}`).
          then(({data}) => {
            this.curriculum = data.data;
            this.updateBreadcrumbs();
          }).
          finally(() => {
            this.loaded = true;
          });
    },
  },
};
</script>

<style scoped lang="scss">
.curriculum {
  max-width: 1280px;
  min-width: 1024px;
  width: 100%;
  &__name{
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 16px;
  }
  &__type{
    margin-bottom: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    opacity: 0.48;
  }
  & .avatar {
    max-height: 56px;
    & img {
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
  }
}
</style>
