<template>
  <VacanciesCatalog/>
</template>

<script>

import VacanciesCatalog from '@/components/Vacancies/VacanciesCatalog';

export default {
  name: 'EmployersCatalogView',

  components: {
    VacanciesCatalog,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>
